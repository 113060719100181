import { create } from 'zustand'

type State = {
  valueQR: string | null
}

type Actions = {
  setValueQR: (valueQR: string) => void
}

const usePaymentStore = create<State & Actions>()((set) => ({
  valueQR: null,
  setValueQR: (valueQR: string) => set((state) => ({ ...state, valueQR })),
}))

export default usePaymentStore
