import { MutableRefObject } from 'react'
import { dataURLtoFile, toggleAddLoading } from '@src/utils/common'
import html2canvas from 'html2canvas'

import { openToast } from '../toaster/custom-toast'

const TransactionDetailFooter = ({
  onClose,
  transactionRef,
}: {
  onClose: () => void
  transactionRef: MutableRefObject<HTMLDivElement | null>
}) => {
  async function handleCreateImageDownload() {
    const el = transactionRef.current
    if (!el) {
      openToast({
        message: 'error',
      })
      return null
    }
    el.classList.add('bg-sub-300')
    const canvas: any = await html2canvas(el)
    const dataURL = canvas.toDataURL('image/png')
    el.classList.remove('bg-sub-300')

    return dataURL
  }

  const handleShare = async () => {
    try {
      toggleAddLoading()
      const dataURL = await handleCreateImageDownload()
      if (!dataURL) {
        toggleAddLoading()
        return
      }
      const file = dataURLtoFile(dataURL, 'transaction-result.png')
      const shareData = { files: [file] }
      await navigator.share(shareData)
      toggleAddLoading()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div className='flex items-center gap-2'>
      <button
        type='button'
        className='w-full rounded-xl border border-primary py-3 font-semibold text-primary'
        onClick={onClose}
      >
        Đóng
      </button>
      <button
        type='button'
        className='w-full rounded-xl border border-primary bg-primary py-3 font-semibold text-white'
        onClick={handleShare}
      >
        Chia sẻ
      </button>
    </div>
  )
}

export default TransactionDetailFooter
