import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { CustomButton } from '@src/components/common/custom-button'
import ModalBottom from '@src/components/modal/modal-bottom'
import { InputField } from '@src/components/sign-in/input-field'

const PaymentConfirm = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const navigate = useNavigate()
  const {
    register,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<{ password: string }>()
  const onSubmit = () => {
    clearErrors()
    navigate('/payment-success')
  }
  return (
    <ModalBottom isOpen={isOpen} onClose={onClose} disableChangeColorTheme>
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6 px-4 text-strong-950'>
        <div className='flex w-full flex-col gap-3'>
          <p className='w-full pt-2 text-center text-sm font-semibold'>Xác nhận mật khẩu</p>
          <p className='w-full text-center text-xs opacity-80'>Vui lòng nhập nhập mật khẩu để xác nhận thanh toán</p>
        </div>

        <div className='w-full'>
          <InputField
            isPassword
            required={true}
            placeholder={'Vui lòng nhập mật khẩu mới'}
            register={register}
            registerValue='password'
            title={'Mật khẩu'}
            sign_in={onSubmit}
            errorsMessage={errors.password?.message}
            showError={!!errors.password?.message}
          />
        </div>

        <CustomButton className='rounded-lg !bg-primary !text-white' text='Xác nhận mật khẩu' type='submit' />
      </form>
    </ModalBottom>
  )
}

export default PaymentConfirm
