import { useEffect, useState } from 'react'
import useConnectDeviceStore from '@src/components/management-device/connect-device/connect-device.store'
import { VIETQR } from '@src/components/management-device/send-messages/generate-qr-payment'
import ModalBottom from '@src/components/modal/modal-bottom'
import { sendQr } from '@src/store/services/pos.service'
import { useShopStore } from '@src/store/states/shop.state'
import { getDataAddMoney, setDataAddMoney } from '@src/utils/local-storage'
import { formatCurrency, reverseFormatCurrency } from '@src/utils/transaction'

import CustomInput from './custom-input'
import SaveDataAddMoney from './save-data-add-money'

export type TAddMoney = {
  amount: number
  content: string
}

const AddMoney = ({
  isOpen,
  dataAddMoney,
  onClose,
  handleChange,
}: {
  isOpen: boolean
  onClose: () => void
  dataAddMoney: TAddMoney
  handleChange: (data: TAddMoney, qr: string) => void
}) => {
  const [isReuse, setIsReuse] = useState(false)
  const [data, setData] = useState<TAddMoney>(dataAddMoney)
  const saveDataAddMoney = getDataAddMoney()
  const { connectDevice } = useConnectDeviceStore()
  const { currentShop } = useShopStore()

  const vietQRdata = new VIETQR()
  useEffect(() => {
    vietQRdata.fields.is_dynamic_qr = true
    vietQRdata.fields.merchant_category = '9999'
    vietQRdata.fields.amount = String(data.amount)
    vietQRdata.fields.acq = '970454'
    vietQRdata.fields.merchant_id = currentShop?.va_account_no ?? ''
    vietQRdata.fields.service_code = 'QRIBFTTA'
    vietQRdata.fields.purpose_txn =
      Array.from(connectDevice.values()).length > 0 ? Array.from(connectDevice.values())[0].pos_code : ''
    vietQRdata.fields.currency = '704'
  }, [data])

  const handleClose = () => {
    setData({ amount: 0, content: '' })
    setIsReuse(false)
    onClose()
  }

  useEffect(() => {
    if (!isOpen) {
      return
    }
    setData(dataAddMoney)
  }, [dataAddMoney, isOpen])

  return (
    <ModalBottom
      disableChangeColorTheme
      header={<div className='py-2 text-sm font-semibold'>Số tiền và lời nhắn</div>}
      onClose={handleClose}
      isOpen={isOpen}
    >
      <div className='flex h-[90dvh] w-full flex-col gap-4 px-4 text-strong-950'>
        <CustomInput
          label='Số tiền'
          placeholder='0'
          inputMode='numeric'
          max={500000000}
          hintOptions={new Array(5).fill(0).map((_, index) => formatCurrency(Math.pow(10, index + 4)) + 'đ')}
          hintOnchange={(value) => {
            setData((p) => ({ ...p, amount: Number(reverseFormatCurrency(value.replace('đ', ''))) }))
          }}
          value={data.amount ? formatCurrency(data.amount) : ''}
          onKeyDownCapture={(e) => {
            if (!/^[0-9]$/.test(e.key) && e.key !== 'Backspace') {
              e.preventDefault()
              e.stopPropagation()
            }
          }}
          onPasteCapture={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onChange={(e) => {
            const value = reverseFormatCurrency(e.target.value)
            const max = e.currentTarget.max ? Number(e.currentTarget.max) : 0
            const res = Number(value) > max ? max : Number(value)
            setData((p) => {
              return { ...p, amount: res }
            })
          }}
          rightContent={<span className='text-sm text-sub-600'>VND</span>}
        />

        <CustomInput
          label='Lời nhắn'
          placeholder='Nhập lời nhắn'
          maxLength={25}
          rightContent={<span className='text-sm text-sub-600'>{data.content.length}/25</span>}
          hintOptions={['Chuyển tiền', 'Tiền ăn uống', 'Tiền hàng']}
          hintOnchange={(value) => {
            setData((p) => ({ ...p, content: value }))
          }}
          value={data.content}
          onChange={(e) => setData((p) => ({ ...p, content: e.target.value }))}
        />

        <SaveDataAddMoney
          data={data}
          onChange={(value: TAddMoney) => setData(value)}
          saveDataAddMoney={saveDataAddMoney}
        />

        <label className='flex w-full gap-2'>
          <input
            type='checkbox'
            checked={isReuse}
            onChange={(e) => setIsReuse(e.target.checked)}
            className='origin-left scale-125 cursor-pointer'
          />
          <span className='text-xs font-normal'>Lưu số tiền và lời nhắn để dùng lại</span>
        </label>

        <div className='flex w-full items-stretch gap-3 pt-4 font-medium'>
          <button
            hidden={Object.values(dataAddMoney).every((value) => !value)}
            className='flex-1 rounded-lg border border-gray py-3'
            onClick={() => {
              handleChange({ amount: 0, content: '' }, '')
              handleClose()
            }}
          >
            Xóa toàn bộ
          </button>
          <button
            className='flex-1 rounded-lg border border-primary bg-primary py-3 text-white'
            onClick={() => {
              if (isReuse) {
                setDataAddMoney([data, ...(saveDataAddMoney ?? [])])
              }
              handleChange(data, vietQRdata.builder())
              Array.from(connectDevice.values()).length > 0 &&
                sendQr({
                  channel: Array.from(connectDevice.values())[0].channel,
                  dataQR: vietQRdata.builder(),
                  amount: Number(data.amount),
                })
              handleClose()
            }}
          >
            Áp dụng
          </button>
        </div>
      </div>
    </ModalBottom>
  )
}

export default AddMoney
