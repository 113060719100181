import axiosClient from './axiosClient'

export const shopQrService = {
  getShopQr: ({ vaAccountNo }: { vaAccountNo: string }) => {
    return axiosClient.post('/portal/user/qr', {
      va_account_no: vaAccountNo,
    })
  },

  getQrCode: async () => {
    return await axiosClient.get('/payment/qr/744', {
      baseURL: 'https://bvbank-stag.atompos.vn/v1',
    })
  },
}
