import { Outlet } from 'react-router-dom'
import Navbar from '@src/components/home/navbar'

const Layout = ({ withNavbar = true }: { withNavbar?: boolean }) => {
  return (
    <>
      <div
        data-navbar={withNavbar}
        className='flex-1 overflow-y-auto overflow-x-hidden bg-main-background pb-navbar-height data-[navbar=false]:pb-ios-pb'
      >
        <Outlet />
      </div>
      {withNavbar && <Navbar />}
    </>
  )
}

export default Layout
