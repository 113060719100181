import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import ForgotPassword from '@src/components/forgot-password'
import { Home } from '@src/components/home'
import ScanQr from '@src/components/payment/scan-qr'
import PaymentSuccess from '@src/components/payment/success'
import PaymentSummary from '@src/components/payment/summary'
import { Report } from '@src/components/report'
import ResetPassword from '@src/components/reset-password'
import Reward from '@src/components/reward'
import Setting from '@src/components/setting'
import SettingTheme from '@src/components/setting-theme'
import SignIn from '@src/components/sign-in'
import Transaction from '@src/components/transaction'
import { ROUTES } from '@src/constants/navbar/routes.constant'
import PrivateRoute from '@src/routes/private-route'
import PublicRoute from '@src/routes/public-route'
import { getMobileOS } from '@src/utils/common'
// import Test from 'emenu/test'
import { AnimatePresence } from 'framer-motion'

import AuthLayout from './auth-layout'
import BankThemeLayout from './bank-theme-layout'
import Layout from './layout'
import MainLayout from './main-layout'

const AppRouter = () => {
  const location = useLocation()

  useEffect(() => {
    const os = getMobileOS()
    if (os === 'Android' || os === 'Other') {
      document.documentElement.style.setProperty('--ios-pb', '8px')
      document.documentElement.style.setProperty('--navbar-height', '76px')
    }
  }, [])

  return (
    <AnimatePresence mode='wait'>
      <Routes location={location} key={location.pathname}>
        <Route element={<BankThemeLayout />}>
          <Route element={<PrivateRoute />}>
            <Route path='/' element={<MainLayout />}>
              <Route path='/' element={<Layout />}>
                <Route path={ROUTES.home} element={<Home />} />
                <Route path={ROUTES.transaction} element={<Transaction />} />
                <Route path={ROUTES.report} element={<Report />} />
                <Route path={ROUTES.reward} element={<Reward />} />
                <Route path={ROUTES.setting} element={<Setting />} />
              </Route>

              <Route path='/' element={<Layout withNavbar={false} />}>
                <Route path={ROUTES.scan_qr} element={<ScanQr />} />
                <Route path={ROUTES.payment_summary} element={<PaymentSummary />} />
                <Route path={ROUTES.payment_success} element={<PaymentSuccess />} />
              </Route>

              {/* <Route path={ROUTES.store_management} element={<Test />} /> */}
              {/* <Route path={`/:tenant`} element={<HomeScreen />} />
              <Route path={`/invoice/:order_id`} element={<Invoice />} />
              <Route path={`/:tenant/order/:table_id/:order_id`} element={<HomeScreen />} />
              <Route path={`/:tenant/order/:table_id`} element={<HomeScreen />} />
              <Route path={`/:tenant/payment-callback`} element={<PaymentStatus />} />
              <Route path={`/:tenant/invoice/:order_id`} element={<Invoice />} />
              <Route path={`/:tenant/payment-qr`} element={<PaymentQr />} /> */}
            </Route>
          </Route>
          <Route element={<PublicRoute />}>
            <Route element={<AuthLayout />}>
              <Route path={ROUTES.sign_in} element={<SignIn />} />
              <Route path={ROUTES.forgot_password} element={<ForgotPassword />} />
              <Route path={ROUTES.reset_password} element={<ResetPassword />} />
            </Route>
          </Route>
        </Route>
        <Route path={ROUTES.setting_theme} element={<SettingTheme />} />
      </Routes>
    </AnimatePresence>
  )
}

export default AppRouter
