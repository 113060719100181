import { useRef } from 'react'
import { IArrowUp, IShieldCheck } from '@src/constants/icons/home.icon'
import { formatCurrency } from '@src/utils/transaction'

import { InforItem } from '.'

const BottomSection = ({ data, handleConfirm }: { data: { amount: number }; handleConfirm: () => void }) => {
  const bottomSection = useRef<HTMLDivElement | null>(null)
  function handleExpand() {
    if (bottomSection.current) {
      bottomSection.current.dataset.expand = bottomSection.current.dataset.expand == 'true' ? 'false' : 'true'
    }
  }
  return (
    <>
      <div ref={bottomSection} data-expand={false} className='group peer relative z-20 w-full rounded-t-2xl bg-white'>
        <div className='absolute bottom-0 left-0 right-0 translate-y-full bg-white pb-ios-pb'></div>
        <div className='flex w-full flex-col px-4 pt-4 text-strong-950'>
          <div className='flex max-h-0 w-full flex-col overflow-hidden transition-all duration-300 group-data-[expand=true]:max-h-[120px]'>
            <div className='mb-4 flex w-full flex-col gap-2 border-b border-dashed border-gray pb-3'>
              <p className='font-semibold'>Tóm tắt thanh toán</p>
              {[
                { title: 'Tạm tính', value: formatCurrency(data.amount) + 'đ' },
                {
                  title: 'Phí dịch vụ',
                  value: 'Miễn phí',
                },
              ].map((item, index) => (
                <InforItem key={index} {...item} />
              ))}
            </div>
          </div>

          <div className='flex w-full items-center gap-4'>
            <div className='flex flex-1 cursor-pointer items-center gap-1' onClick={handleExpand}>
              <p className='flex-1 text-lg font-semibold'>{formatCurrency(data.amount) + 'đ'}</p>
              <span className='relative block size-[18px] group-data-[expand=true]:text-primary'>
                <span className='absolute inset-0 rotate-0 transition-all duration-300 group-data-[expand=true]:rotate-180'>
                  <IArrowUp />
                </span>
              </span>
            </div>
            <button
              onClick={handleConfirm}
              className='flex flex-1 items-center justify-center gap-1 rounded-lg bg-primary px-3 py-2.5 text-sm font-semibold text-white'
            >
              <IShieldCheck />
              Xác nhận
            </button>
          </div>
        </div>
      </div>
      <div
        onClick={handleExpand}
        className='pointer-events-none absolute inset-0 z-10 bg-overlay opacity-0 transition-all peer-data-[expand=true]:pointer-events-auto peer-data-[expand=true]:opacity-100'
      ></div>
    </>
  )
}

export default BottomSection
