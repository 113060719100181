import { createPortal } from 'react-dom'
import { AnimatePresence, motion } from 'framer-motion'

const ModalWrapper = ({ isOpen, children, id }: { isOpen: boolean; children: React.ReactNode; id?: string }) => {
  const container = document.getElementById(id ?? 'root')
  return createPortal(
    <AnimatePresence mode='wait'>
      {isOpen && (
        <motion.div
          className='fixed inset-0 z-modal flex items-center justify-center'
          onTouchStart={(e) => e.stopPropagation()}
          onScroll={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <div className='h-full w-full overflow-hidden sm:w-limitWidth'>{children}</div>
        </motion.div>
      )}
    </AnimatePresence>,
    container ?? document.body
  )
}

export default ModalWrapper
