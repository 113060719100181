import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { IHomeSmall } from '@src/constants/icons/transaction.icon'
import { ModalProps } from '@src/constants/modal/modal'
import { ROUTES } from '@src/constants/navbar/routes.constant'
import { TTransaction } from '@src/types/transaction'
import { wait } from '@src/utils/common'

import { LayoutHeader } from '../common/layout-header'
import ModalDirection from '../modal/modal-direction'

import TransactionDetailBody from './transaction-detail-body'
import TransactionDetailFooter from './transaction-detail-footer'

const TransactionDetail = ({ transaction, ...props }: ModalProps & { transaction: TTransaction }) => {
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement | null>(null)

  return (
    <ModalDirection
      className='!bg-weak-50'
      direction='right'
      {...props}
      header={
        <LayoutHeader
          onBack={() => {
            props.onClose()
          }}
          title={'Chi tiết giao dịch thẻ'}
          rightItem={
            <span
              className='text-strong-950 active:opacity-active'
              onClick={async () => {
                props.onClose()
                await wait(350)
                navigate(ROUTES.home)
              }}
            >
              <IHomeSmall />
            </span>
          }
        />
      }
      footer={
        <div className='w-full px-4 pt-2'>
          <TransactionDetailFooter onClose={props.onClose} transactionRef={ref} />
        </div>
      }
    >
      <div ref={ref} className='p-4'>
        <TransactionDetailBody transaction={transaction} />
      </div>
    </ModalDirection>
  )
}

export default TransactionDetail
