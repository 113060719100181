import React from 'react'

interface IconWrapper {
  children?: React.ReactNode
  // size: '24px' | '34px' | '48px' | '76px'
  size: number
  onClick?: () => void
  rounded?: '12px' | '16px' | 'full'
  isNew?: boolean
  className?: string
}

const IconWrapper = ({ children, size, rounded, isNew, className, onClick }: IconWrapper) => {
  return (
    <div
      className={`${className} relative flex items-center justify-center border border-primary/10 text-primary [--secondary-color:theme(colors.secondary)]`}
      style={{
        width: size,
        height: size,
        borderRadius: rounded === 'full' ? '50%' : rounded,
      }}
      onClick={() => onClick && onClick()}
    >
      {children}

      <span
        data-new={isNew}
        className='invisible absolute bottom-[-2px] right-[-2px] size-[10px] rounded-full border border-white bg-success-base data-[new=true]:visible'
      ></span>
    </div>
  )
}

export default IconWrapper
