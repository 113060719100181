import { home_banner } from '@src/constants/bank.constants'
import { ex_feature, ex_modules } from '@src/constants/home.constants'
import { IHomeBg } from '@src/constants/icons/home.icon'
import { wait } from '@src/utils/common'
import { getThemeBank } from '@src/utils/theme-color'
import { useQueryClient } from '@tanstack/react-query'

import PullToRefresh from '../pull-to-refresh/pull-to-refresh'
import QRScan from '../transaction/qr-scan'

import Balance from './balance'
import { CarouselSection } from './carousel-section'
import HeaderInfo from './header'
import { RecentTransactions } from './recent-transactions'

export const Home = () => {
  const queryClient = useQueryClient()
  return (
    <>
      <div className='flex h-full flex-col'>
        <HomeBackground />
        <div className='relative z-10 w-full overflow-visible'>
          <HeaderInfo />
        </div>
        <PullToRefresh
          onRefresh={async () => {
            queryClient.invalidateQueries({ queryKey: ['report-dashboard'] })
            await wait(700)
          }}
        >
          <div
            className='carousel relative size-full overflow-y-auto overflow-x-hidden'
            onScroll={(e) => {
              const scrollTop = e.currentTarget.scrollTop
              const homeBody = document.getElementById('home-body')
              const appHeader = document.getElementById('app-header')
              const balance = document.getElementById('home-balance-shorten')
              if (!homeBody || !appHeader || !balance) {
                return
              }
              const atHeight = 136
              if (scrollTop > 0) {
                const els = appHeader.querySelectorAll(':scope > div')
                const minScale = 0.75
                const scale = Math.max(1 - ((1 - minScale) * scrollTop) / atHeight, minScale)
                for (let i = 0; i < els.length; i++) {
                  ;(els[i] as HTMLElement).style.scale = scale.toString()
                }
              } else {
                const els = appHeader.querySelectorAll(':scope > div')
                for (let i = 0; i < els.length; i++) {
                  ;(els[i] as HTMLElement).style.scale = '1'
                }
              }

              if (scrollTop >= atHeight) {
                balance.setAttribute('data-shorten', 'true')
                balance.classList.add('shadow')
              } else {
                balance.setAttribute('data-shorten', 'false')
                balance.classList.remove('shadow')
              }
            }}
          >
            <div
              id='home-body'
              className='relative flex translate-y-0 flex-col gap-2 p-4 opacity-100 transition-all duration-300'
            >
              <Balance />
              <div id='recent-transaction' className='w-full'>
                <RecentTransactions />
              </div>
              <CarouselSection id='service' modules={ex_modules} title={'Dịch vụ'} />
              <CarouselSection id='feature' modules={ex_feature} title={'Tiện ích'} className='!px-1' />
              <img src={home_banner[getThemeBank()]} alt='' className='h-[166px] w-full rounded-2xl object-cover' />
            </div>
            <QRScan />
          </div>
        </PullToRefresh>
      </div>
    </>
  )
}

export const HomeBackground = () => {
  return (
    <span
      id='app-home-bg'
      className='absolute left-0 right-0 top-0 opacity-100 transition-all [--darker-color:theme(colors.primary-darker)] [--light-color:theme(colors.primary)]'
    >
      <IHomeBg />
    </span>
  )
}
