import { useNavigate } from 'react-router-dom'
import { ADD_DEVICE, MANAGEMENT_DEVICE, OPEN } from '@src/constants/key.constants'
import { useQueryParams } from '@src/hooks/use-query-params'
import { TDevice, TDeviceStatus } from '@src/types/device'

import { CustomButton } from '../common/custom-button'
import { LayoutHeader } from '../common/layout-header'
import ModalDirection from '../modal/modal-direction'

import useConnectDeviceStore from './connect-device/connect-device.store'
import { CarouselFilter } from './carosel-filter'
import ConnectDevice from './connect-device'
import DeviceDetail from './device-detail'
import { EmptyDevice } from './empty-device'

export type TTab = {
  id: string
  name: string
  value: TDeviceStatus | 'all'
}

const device_status: TTab[] = [
  {
    id: '0',
    name: 'Tất cả',
    value: 'all',
  },
  {
    id: '1',
    name: 'Hoạt động',
    value: 'active',
  },
  {
    id: '2',
    name: 'Chờ kích hoạt',
    value: 'activation',
  },
  {
    id: '3',
    name: 'Khóa',
    value: 'block',
  },
]
export const devices: TDevice[] = [
  {
    id: 0,
    device_id: 'FD2ZQ0KCJCM4',
    device_name: 'Xiaomi Redmi Note 10 Pro',
    account: 'Julia',
    update_time: '16:21:26 - 02/10/2024',
    store_name: 'Starbucks Landmark 81',
    branch_name: 'Starbucks Miền Bắc',
    agency_name: 'Starbucks Việt Nam',
    status: 'active',
    channel: '',
    pos_code: '',
    otp_code: '',
  },
  {
    id: 1,
    device_id: 'CJCM4FD2ZQ0K',
    device_name: 'Iphone XS Max',
    account: 'Jame',
    update_time: '10:06:33 - 07/11/2024',
    store_name: 'Starbucks Thirsty Hall',
    branch_name: 'Starbucks Miền Nam',
    agency_name: 'Starbucks Việt Nam',
    status: 'activation',
    channel: '',
    pos_code: '',
    otp_code: '',
  },
  {
    id: 2,
    device_id: 'ZQ0KCJCM4FD2',
    device_name: 'Samsung Galaxy S21',
    account: 'Scott',
    update_time: '7:14:25 - 08/11/2024',
    store_name: 'Starbucks Vincom',
    branch_name: 'Starbucks Miền Nam',
    agency_name: 'Starbucks Việt Nam',
    status: 'block',
    channel: '',
    pos_code: '',
    otp_code: '',
  },
]

const ManagementDevice = () => {
  const { getParams, setParams } = useQueryParams()
  const navigate = useNavigate()
  const handleCloseConnectDevice = () => {
    navigate(-1)
  }
  const { connectDevice } = useConnectDeviceStore()
  return (
    <>
      <ModalDirection
        isOpen={getParams(OPEN)?.includes(MANAGEMENT_DEVICE) ?? false}
        onClose={() => {
          navigate(-1)
        }}
        className='!bg-weak-50'
        header={
          <LayoutHeader
            onBack={() => {
              navigate(-1)
            }}
            title={'Quản lý thiết bị POS'}
          />
        }
        footer={
          <div className='w-full p-4 pb-0'>
            <CustomButton
              onClick={() => {
                setParams({ [OPEN]: MANAGEMENT_DEVICE + '%' + ADD_DEVICE })
              }}
              text={'Đăng ký thiết bị mới'}
              className={'!rounded-[10px] !bg-primary text-white'}
            />

            <ModalDirection
              disableChangeColorTheme
              header={<LayoutHeader onBack={handleCloseConnectDevice} title='Đăng ký thiết bị mới' />}
              footer={
                <div className='w-full p-4 pb-0 text-center text-sm text-neutral-300'>
                  Cần trợ giúp?
                  <span className='ml-1 text-primary'>Liên hệ hỗ trợ kĩ thuật</span>
                </div>
              }
              isOpen={getParams(OPEN)?.includes(ADD_DEVICE) ?? false}
              onClose={handleCloseConnectDevice}
            >
              <ConnectDevice closeModal={handleCloseConnectDevice} />
            </ModalDirection>
          </div>
        }
      >
        {Array.from(connectDevice.values()).length === 0 ? <EmptyDevice /> : <CarouselFilter type={device_status} />}
      </ModalDirection>
      <DeviceDetail />
    </>
  )
}

export default ManagementDevice
