export const ROUTES = {
  home: '/',
  report: '/report',
  transaction: '/transaction',
  reward: '/reward',
  setting: '/setting',
  sign_in: '/sign_in',
  setting_theme: '/bank_theme',
  forgot_password: '/forgot_password',
  reset_password: '/reset_password',
  scan_qr: '/scan-qr',
  store_management: 'store_management',
  payment_summary: '/payment-summary',
  payment_success: '/payment-success',
}
