import { useEffect, useRef, useState } from 'react'
import QRCode from 'react-qr-code'
import { useNavigate } from 'react-router-dom'
import { LayoutHeader } from '@src/components/common/layout-header'
import ModalDirection from '@src/components/modal/modal-direction'
import { logo } from '@src/constants/bank.constants'
import { IForbiddenCircle, IPen } from '@src/constants/icons/home.icon'
import { IForward } from '@src/constants/icons/transaction.icon'
import { MERCHANT_QR, OPEN } from '@src/constants/key.constants'
import { useQueryParams } from '@src/hooks/use-query-params'
import { useSocket } from '@src/hooks/use-socket'
import { shopQrService } from '@src/store/services/shop-qr.service'
import { useShopStore } from '@src/store/states/shop.state'
import { dataURLtoFile, toggleAddLoading } from '@src/utils/common'
import { getThemeBank } from '@src/utils/theme-color'
import { formatCurrency } from '@src/utils/transaction'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { motion } from 'framer-motion'
import html2canvas from 'html2canvas'

import AddMoney, { TAddMoney } from './add-money'

export default function QRScan() {
  const { getParams, deleteParams } = useQueryParams()
  const openQR = getParams(OPEN) === MERCHANT_QR
  const navigate = useNavigate()
  const { currentShop } = useShopStore()
  const [staticQR, setStaticQR] = useState('')
  const [dynamicQR, setDynamicQR] = useState('')

  const myAudio = useRef<HTMLAudioElement | null>(null)

  const [activeBg, setActiveBg] = useState(0)
  const [openAddMoney, setOpenAddMoney] = useState(false)
  const [dataAddMoney, setDataAddMoney] = useState<TAddMoney>({
    amount: 0,
    content: '',
  })

  async function handleCreateImageDownload() {
    const el = document.getElementById('qr-generate') as HTMLElement
    if (dataAddMoney.amount == 0) {
      el.dataset.money = 'true'
    }
    el.dataset.download = 'true'
    const canvas: any = await html2canvas(el)
    const dataURL = canvas.toDataURL('image/png')
    el.dataset.download = 'false'
    el.dataset.money = 'false'
    return dataURL
  }

  // const handleDownload = async () => {
  //   const dataURL = await handleCreateImageDownload()
  //   downloadjs(dataURL, 'qr-code.png', 'image/png')
  // }

  const handleShare = async () => {
    try {
      toggleAddLoading()
      const dataURL = await handleCreateImageDownload()
      const file = dataURLtoFile(dataURL, 'qr-code.png')
      const shareData = { files: [file] }
      await navigator.share(shareData)
      toggleAddLoading()
    } catch (error) {
      console.log(error)
      toggleAddLoading()
    }
  }

  const { mutate: getQR, isPending } = useMutation({
    mutationFn: async () => await shopQrService.getShopQr({ vaAccountNo: currentShop?.va_account_no ?? '' }),
    onSuccess: (data) => setStaticQR(data.data.qr_base64),
  })

  const queryClient = useQueryClient()

  useEffect(() => {
    if (openQR && currentShop?.va_account_no) getQR()
  }, [openQR, currentShop])

  useSocket({
    url: 'https://bvbank-stag-socket.atompos.vn',
    emitEvent: {
      name: 'subscribe',
      payload: { merchant_id: String(2877) },
    },
    onEvent: {
      name: 'payment_success',
      func: async ({ data }) => {
        if (data) {
          myAudio.current && myAudio.current.play()
          queryClient.invalidateQueries({ queryKey: ['report-dashboard'] })
        }
      },
    },
  })

  const onClose = () => {
    try {
      navigate(-1)
    } catch (error) {
      deleteParams(OPEN)
    }
    setActiveBg(0)
    setOpenAddMoney(false)
    setDataAddMoney({ amount: 0, content: '' })
  }
  console.log(dataAddMoney)
  return (
    <>
      <audio
        ref={myAudio}
        src={'https://res.cloudinary.com/dgpmtcqtj/video/upload/v1705738800/lak03qewvuvvcihhqagv.mp3'}
      />
      <ModalDirection
        isOpen={openQR}
        onClose={onClose}
        className='!bg-weak-50'
        direction='right'
        header={
          <LayoutHeader
            onBack={onClose}
            title='Quét mã để chuyển tiền'
            rightItem={
              <button onClick={handleShare}>
                <IForward />
              </button>
            }
          />
        }
      >
        <div className='flex size-full flex-col items-center justify-between gap-4 bg-weak-50 p-4'>
          <div
            id='qr-generate'
            data-download={false}
            data-money={false}
            className='group relative flex w-full flex-1 flex-col items-center justify-center overflow-hidden rounded-2xl bg-secondary data-[download=true]:rounded-none'
          >
            <div id='bg-qr-generate' className='absolute inset-0'></div>

            <div className='relative flex aspect-[13/20] max-h-[90%] max-w-[80%] flex-col items-center justify-center gap-4 rounded-2xl border border-gray/50 bg-white p-6 shadow-2xl'>
              <div className='flex items-center gap-4'>
                <img src={logo[getThemeBank()]} alt='' className='h-6' />
                <div className='h-8 w-[1px] bg-sub-600'></div>
                <div className='relative flex-1'>
                  <div className='flex flex-col gap-5 text-sm font-semibold leading-[0px] text-strong-950 group-data-[download=true]:-translate-y-[8px]'>
                    <p>QR Nhận Tiền</p>
                    <p className='flex items-center text-primary'>Đa Năng </p>
                  </div>
                </div>
              </div>
              <div className='flex w-full flex-col items-center text-strong-950'>
                <p className='font-bold uppercase'>{currentShop?.name}</p>

                {/* <p className='text-sm font-normal leading-6 text-sub-600'>
                  Tài khoản QR:
                  <span className='inline-block font-semibold'> {currentShop?.va_account_no}</span>
                </p> */}

                <p className='text-sm font-normal leading-6 text-sub-600'>
                  STK:
                  <span className='font-semibold'> {currentShop?.va_account_no}</span>
                </p>
              </div>

              <div className='flex w-full flex-col items-center gap-2 px-4'>
                <div className='flex w-full items-stretch px-4'>
                  <div className='flex-1'>
                    <img className='size-full object-cover' src='/common/viet-qr.png' />
                  </div>
                  <div className='flex-1'>
                    <img className='size-full object-cover' src='/common/napas.png' />
                  </div>
                </div>

                <div className='relative aspect-square w-full'>
                  {!isPending ? (
                    dataAddMoney.amount > 0 ? (
                      <QRCode
                        value={dynamicQR}
                        size={218}
                        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                      />
                    ) : (
                      <img
                        src={`data:image/png;base64,${staticQR}`}
                        alt=''
                        className='w-full rounded-md border border-gray/20 object-cover'
                      />
                    )
                  ) : (
                    <div className='aspect-square w-full animate-pulse rounded bg-soft-200'></div>
                  )}
                </div>
              </div>

              <button
                id='qr-generate-data'
                onClick={() => setOpenAddMoney(true)}
                className='flex w-full items-center justify-center text-lg font-semibold group-data-[money=true]:hidden'
              >
                {dataAddMoney.amount > 0 ? (
                  <span className='flex w-full flex-col'>
                    <span className='relative'>
                      {formatCurrency(dataAddMoney.amount)} VND
                      <span className='absolute right-1 top-1/2 -translate-y-1/2 translate-x-full group-data-[download=true]:hidden'>
                        <span className='block origin-left scale-[60%]'>
                          <IPen />
                        </span>
                      </span>
                    </span>
                    <span className='text-sm font-normal'>{dataAddMoney.content}</span>
                  </span>
                ) : (
                  <>+ Thêm số tiền</>
                )}
              </button>
              <AddMoney
                isOpen={openAddMoney}
                onClose={() => setOpenAddMoney(false)}
                dataAddMoney={dataAddMoney}
                handleChange={(value: TAddMoney, qr: string) => {
                  setDataAddMoney(value)
                  setDynamicQR(qr)
                }}
              />
            </div>
          </div>

          <div className='mt-3 flex w-full flex-nowrap overflow-auto'>
            {[
              {
                icon: IForbiddenCircle,
              },
              '/bg1.jpg',
              '/bg1.1.jpg',
              '/bg1.2.jpg',
              '/bg2.jpg',
              '/bg3.jpg',
              '/bg4.jpg',
              '/bg5.jpg',
              '/bg6.jpg',
              '/bg7.jpg',
              '/bg8.jpg',
            ].map((item, index) => {
              const isActive = activeBg === index
              const imgSrc = typeof item == 'string' ? '/common/qr-generate' + item : undefined
              return (
                <button
                  key={index}
                  className={`relative min-w-fit p-1`}
                  onClick={() => {
                    const el = document.getElementById('bg-qr-generate')
                    if (!el) {
                      return
                    }
                    const image = el.querySelector('img')
                    if (imgSrc) {
                      if (image) {
                        image.src = imgSrc
                      } else {
                        const img = document.createElement('img')
                        img.src = imgSrc
                        img.className = 'size-full object-cover'
                        el.appendChild(img)
                      }
                    } else if (image) {
                      el.removeChild(image)
                    }
                    setActiveBg(index)
                  }}
                >
                  {isActive && (
                    <motion.div
                      layoutId='ative-bg-add-money'
                      transition={{ type: 'spring', stiffness: 500, damping: 40 }}
                      className='absolute inset-0 rounded-[0.7rem] border-[1.5px] border-primary'
                    ></motion.div>
                  )}
                  <div
                    data-active={isActive}
                    className='relative flex size-9 items-center justify-center overflow-hidden rounded-lg bg-gray/50 data-[active=true]:text-primary'
                  >
                    {typeof item == 'string' ? (
                      <>
                        <img className='size-full object-cover' src={imgSrc} alt='' />
                      </>
                    ) : (
                      <>{item?.icon ? item.icon() : ''}</>
                    )}
                  </div>
                </button>
              )
            })}
          </div>
        </div>
      </ModalDirection>
    </>
  )
}
