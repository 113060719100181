import { ChangeEvent, useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import usePaymentStore from '@src/store/states/payment.state'

import { openToast } from '../../toaster/custom-toast'

import { EXCEPTIONS, Exceptions } from './scan-qr.constants'
import { closeCamera, connectCamera, getBarcodeDetector, openCamera } from './scan-qr.controller'

import styles from './scan-qr.module.css'

const FRAMES = 1200

function ScanQr() {
  const navigate = useNavigate()
  const { setValueQR } = usePaymentStore()
  const refStream = useRef<MediaStream>()
  const refVideo = useRef<HTMLVideoElement>(null)
  const requestRef = useRef<number>()
  const barcodeRef = useRef<DetectedBarcode[]>()

  const handleNavigate = (to: string) => {
    if (!document?.startViewTransition) {
      navigate(to)
    } else {
      document.startViewTransition(async () => {
        await navigate(to)
      })
    }

    closeCamera({
      stream: refStream.current,
      video: refVideo.current,
    })
  }

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const currentFile = event.target.files?.[0]

    if (currentFile) {
      closeCamera({ video: refVideo.current, stream: refStream.current })

      const img = new Image()

      img.src = URL.createObjectURL(currentFile)

      img.onload = async () => {
        const res = await getBarcodeDetector({
          source: img,
        })
        console.log('Log ~ img.onload= ~ res:', res)
      }
    }
  }

  const handleBarcode = useCallback(async () => {
    if (
      typeof requestRef.current !== 'undefined' &&
      requestRef.current < FRAMES &&
      (!barcodeRef.current || barcodeRef.current?.length === 0)
    ) {
      try {
        const barcodes = await getBarcodeDetector({
          source: refVideo.current,
        })
        if (barcodes && barcodes.length > 0) {
          barcodeRef.current = barcodes
          setValueQR(barcodes[0].rawValue)
          closeCamera({
            stream: refStream.current,
            video: refVideo.current,
          })
          navigate('/payment-summary')
        }
        requestRef.current++
        requestAnimationFrame(() => handleBarcode())
      } catch (error) {
        console.log('Log - error: ', error)
        openToast({
          message: 'Some issue with barcode',
          type: 'error',
        })
      }
    } else {
      if (requestRef.current && requestRef.current >= FRAMES)
        openToast({
          message: 'Timeout scan qr',
          type: 'error',
        })
      if (requestRef.current) cancelAnimationFrame(requestRef.current)
      refVideo.current?.pause()
      requestRef.current = undefined
      barcodeRef.current = undefined
    }
  }, [])

  const onMount = useCallback(async () => {
    try {
      const stream = await connectCamera()
      refStream.current = stream

      openCamera({
        video: refVideo.current,
        stream: refStream.current,
      })

      requestRef.current = 0
      handleBarcode()
    } catch (error: any) {
      openToast({
        message: EXCEPTIONS[error.name as Exceptions] ?? error.message,
        type: 'error',
        duration: 3000,
      })
    }
  }, [handleBarcode])

  const unMount = () => {
    closeCamera({ video: refVideo.current, stream: refStream.current })
    if (requestRef.current) cancelAnimationFrame(requestRef.current)
    requestRef.current = undefined
    barcodeRef.current = undefined
  }

  useEffect(() => {
    onMount()
    return unMount
  }, [onMount])

  return (
    <div className={`${styles.scanQrWrapper}`}>
      <video playsInline ref={refVideo} autoPlay muted className={styles.videoCamera}></video>

      <div className={styles.borderScan}>
        <div className={`${styles.borderAngle}`}></div>
        <div className={`${styles.borderAngle} ${styles.topRight}`}></div>
        <div className={`${styles.borderAngle} ${styles.leftBottom}`}></div>
        <div className={`${styles.borderAngle} ${styles.rightBottom}`}></div>
      </div>

      <div className={styles.content}>
        <div className={styles.box1}>
          <div className={styles.action}>
            <button className={styles.button}>
              <img src='/common/icon-thunder.svg' alt='icon thunder' className={styles.icon} />
            </button>
            <button onClick={() => handleNavigate('/')} className={styles.button}>
              <img src='/common/icon-close.svg' alt='icon close' className={styles.icon} />
            </button>
          </div>
          <div className={styles.title}>
            <p>Quét mã QR để thanh toán - chuyển khoản - rút tiền - nộp tiền</p>
          </div>
        </div>
        <div className={styles.box2}>
          <label className={styles.uploadFile}>
            <input type='file' hidden accept='image/*' onChange={(event) => handleUploadFile(event)} />
            <img src='/common/icon-image.svg' alt='icon image' className={styles.icon} />
            <span>Chọn ảnh từ thư viện</span>
          </label>

          <button onClick={() => handleNavigate('/?open=merchant_qr')} type='button' className={styles.button}>
            Mã QR của tôi
          </button>
        </div>
      </div>
    </div>
  )
}

export default ScanQr
