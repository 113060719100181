import { IDetailDivider } from '@src/constants/icons/transaction.icon'
import { TTransaction } from '@src/types/transaction'
import { getThemeBank } from '@src/utils/theme-color'
import { formatCurrency, formatTransactionStatus } from '@src/utils/transaction'

const TransactionDetailBody = ({ transaction }: { transaction: TTransaction }) => {
  return (
    <div
      data-status={transaction.status}
      className='group data-[status=canceled]:text-faded-base data-[status=failed]:text-error-base data-[status=pending]:text-secondary data-[status=success]:text-primary'
    >
      {/* Body */}
      <div className='w-full drop-shadow-sm'>
        {/* Header */}
        <div
          data-status={transaction.status}
          className='group rounded-t-2xl data-[status=canceled]:bg-faded-base data-[status=failed]:bg-error-base data-[status=pending]:bg-secondary data-[status=success]:bg-primary'
        >
          <p className='w-full py-4 text-center text-lg font-semibold text-white'>
            {formatTransactionStatus(transaction.status).full}
          </p>
        </div>

        {/* Body */}
        <div className='relative overflow-hidden rounded-b-2xl'>
          {/*  */}
          <div className='relative bg-white p-4'>
            <div className='flex flex-col gap-2'>
              <div className='flex w-full flex-col items-center gap-1'>
                <p className='text-sm font-normal text-sub-600'>Số tiền (VND)</p>
                <p className='text-2xl font-bold leading-[29.05px]'>
                  {transaction.is_received ? '+' : '-'}
                  {formatCurrency(transaction.amount)} VND
                </p>
              </div>

              {[
                { title: 'Loại giao dịch', value: 'GD thẻ' },
                { title: 'Dịch vụ', value: 'NA272' },
              ].map((item, index) => (
                <TextItem key={index} title={item.title} value={item.value} />
              ))}
            </div>
          </div>

          <div className='relative text-white'>
            <IDetailDivider />
          </div>

          {/*  */}
          <div className='relative bg-white p-4 pt-0'>
            <div className='flex flex-col gap-4'>
              <TextItem title='Mã hoá đơn:' value='NA272' className='!flex-col !items-start' />
              <TextItem title='Mã hoá đơn:' value='NA272' className='!flex-col !items-start' />

              <div className='flex flex-col gap-2'>
                {[
                  { title: 'Mã giao dịch', value: 'NA272' },
                  { title: 'Thời gian giao dịch', value: '12:23 - 02/10/2024' },
                  { title: 'Số tiền giao dịch', value: '10.000.000 đ' },
                ].map((item, index) => (
                  <TextItem key={index} title={item.title} value={item.value} />
                ))}

                <TextItem
                  title={'Nội dung giao dịch'}
                  className='!flex-col !items-start !gap-2'
                  textClassName='p-4 !font-normal !text-sm bg-weak-50 !text-[#2C2C2C] rounded-[8px]'
                  value={
                    'Công cha như núi thái sơn, nghĩa mẹ như nước trong nguồn chảy ra. Một lòng thờ mẹ kính cha. Cho tròn chữ hiếu mới là đạo con...'
                  }
                />
              </div>
            </div>
          </div>

          {/*  */}
          {getThemeBank() === 'ocb' && (
            <div className='absolute left-1/2 top-[28.5%] z-10 w-[62.5%] -translate-x-1/2'>
              <img src='./bank-theme/OCB_TransparentLogo.png' className='w-full object-contain' />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default TransactionDetailBody

function TextItem({
  title,
  value,
  className,
  textClassName,
}: {
  title: string
  value: string
  className?: string
  textClassName?: string
}) {
  return (
    <div className={`${className} flex items-center gap-1 text-sm`}>
      <p className='flex-1 font-normal text-sub-600'>{title}</p>
      <p className={`font-semibold text-black ${textClassName}`}>{value}</p>
    </div>
  )
}
