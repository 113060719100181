export const CustomButton = ({
  text,
  onClick,
  className,
  type,
  hidden,
  disable,
  id,
}: {
  text: string
  onClick?: () => void
  className?: string
  type?: 'button' | 'submit' | 'reset'
  hidden?: boolean
  disable?: boolean
  id?: string
}) => {
  return (
    <button
      id={id}
      type={type}
      hidden={hidden}
      disabled={disable}
      className={`${className} w-full rounded-full bg-primary/10 px-10 py-3 text-primary disabled:opacity-70`}
      onClick={onClick}
    >
      <span className='truncate text-base font-medium leading-5 tracking-[-0.16px]'>{text}</span>
    </button>
  )
}
