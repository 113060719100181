import axios from 'axios'

const URL_API_POS = import.meta.env.VITE_APP_URL_API_POS_CONTROLLER
console.log('Log ~ URL_API_POS:', URL_API_POS)

const posAxios = axios.create({
  baseURL: URL_API_POS,
})

type TBodyConnectPos = {
  pos_code: string
  otp_code: string
}

type TBodySendMessage = {
  channel: string
  data: object
}

export const posService = {
  requestPos: (body: Omit<TBodyConnectPos, 'otp_code'>) => posAxios.post('/api/pos/request/auth', body),
  verifyPos: (body: TBodyConnectPos) =>
    posAxios.post<{
      pos_code: string
      otp_code: string
      id: number
      channel: string
    }>('/api/pos/auth', body),
  sendMessage: (body: TBodySendMessage) => posAxios.post('/api/pos/send/message', body),
}

export const sendQr = ({ channel, dataQR, amount }: { channel: string; dataQR: string; amount: number }) => {
  return posAxios.post('/api/pos/send/message', {
    channel: channel,
    data: {
      type: 'qr',
      dataQR: dataQR,
      amount: amount,
    },
  })
}
