import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import Drawer from 'react-modern-drawer'
import { CHATBOT_EMBEDDED_URL } from '@src/constants/chatbot'
import { useQueryParams } from '@src/hooks/use-query-params'
import { getThemeBank } from '@src/utils/theme-color'

import ChatbotSkeleton from './skeleton'

import 'react-modern-drawer/dist/index.css'

export default function Chatbot() {
  const [chatLoaded, setChatLoaded] = useState(false)
  const { getParams, deleteParams } = useQueryParams()

  const isOpen = getParams('chatbot') === 'true'

  const token = localStorage.getItem('accessToken') || 'default'
  const bank = getThemeBank()

  const closeChatbot = useCallback(() => {
    deleteParams('chatbot')
  }, [deleteParams])

  const loadChatbot = useCallback(() => {
    const isScriptLoaded = document.getElementById('chatbot-script')
    if (isScriptLoaded) return

    const script = document.createElement('script')
    script.id = 'chatbot-script'
    script.src = `${CHATBOT_EMBEDDED_URL}?version=${Date.now()}`
    script.async = true
    script.onload = () => setChatLoaded(true)
    script.onerror = () => {
      toast.error('An error occurred while loading the chatbot. Please try again later.')
      closeChatbot()
    }

    const body = document.getElementsByTagName('body')[0]
    if (body) body.appendChild(script)
  }, [closeChatbot])

  useEffect(() => {
    if (chatLoaded || !isOpen) return

    loadChatbot()
  }, [token, chatLoaded, isOpen, loadChatbot])

  useEffect(() => {
    function handleMessage(event: MessageEvent<any>) {
      const type = event.data?.type
      if (type === 'close-chat') closeChatbot()
    }

    window.addEventListener('message', handleMessage)
    return () => window.removeEventListener('message', handleMessage)
  }, [closeChatbot])

  return (
    <Drawer
      open={isOpen}
      onClose={closeChatbot}
      direction='bottom'
      size='100vh'
      overlayOpacity={0}
      className='!bg-transparent !shadow-none'
    >
      <div className='relative mx-auto h-screen max-h-screen overflow-hidden sm:w-limitWidth'>
        {!chatLoaded && (
          <div className='absolute inset-0 flex items-center justify-center'>
            <ChatbotSkeleton />
          </div>
        )}
        <div
          data-theme={bank}
          data-access-token={token}
          id='chat-container'
          style={{ display: isOpen ? 'block' : 'none' }}
          className='relative size-full'
        ></div>
      </div>
    </Drawer>
  )
}
