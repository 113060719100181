import { TAddMoney } from '@src/components/transaction/qr-scan/add-money'

import { wait } from './common'

export const ACCESS_TOKEN = 'accessToken'
export const REFRESH_TOKEN = 'refreshToken'

export function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN)
}

export function setAccessToken(accessToken: string) {
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN, accessToken)
  }
}

export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN)
}

export function setRefreshToken(refreshToken: string) {
  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN, refreshToken)
  }
}

export function getIsNotSignIn() {
  return !getRefreshToken() && !getAccessToken()
}

export function clearToken() {
  localStorage.clear()
}

export function getIsViewInThread() {
  let res = localStorage.getItem('isViewInThread')
    ? localStorage.getItem('isViewInThread') === 'true'
      ? true
      : false
    : undefined
  if (res == undefined) {
    localStorage.setItem('isViewInThread', 'true')
    res = true
  }
  return res
}

export function getShowIntro() {
  let res = localStorage.getItem('showIntro')
    ? localStorage.getItem('showIntro') === 'true'
      ? true
      : false
    : undefined
  if (res == undefined) {
    localStorage.setItem('showIntro', 'true')
    res = true
  }
  return res
}

export async function setShowIntro(showIntro: boolean) {
  if (showIntro) {
    localStorage.setItem('showIntro', 'true')
  } else {
    localStorage.setItem('showIntro', 'false')
  }
}

export async function animateHome(flag: boolean) {
  const appHeader = document.getElementById('app-header')
  const appNavbar = document.getElementById('app-navbar')
  const homeBody = document.getElementById('home-body')
  const homeBalance = document.getElementById('home-balance')
  if (!homeBody || !homeBalance || !appHeader || !appNavbar) {
    return
  }
  if (flag) {
    appHeader.classList.add('!-translate-y-full', 'duration-300')
    appNavbar.classList.add('!translate-y-full', 'duration-300')
    homeBody.classList.add('!opacity-0', '!translate-y-40', 'delay-[300ms]')
    homeBalance.classList.add('!max-h-0', '!translate-x-full', 'delay-[800ms]')
  } else {
    appHeader.classList.remove('!-translate-y-full')
    appNavbar.classList.remove('!translate-y-full')
    homeBody.classList.remove('!opacity-0', '!translate-y-40')
    homeBalance.classList.remove('!max-h-0', '!translate-x-full')

    await wait(3000)
    appHeader.classList.remove('duration-300')
    appNavbar.classList.remove('duration-300')
    homeBody.classList.remove('delay-[300ms]')
    homeBalance.classList.remove('delay-[1200ms]')
  }
}

export function getInstallPWA() {
  const res = localStorage.getItem('isWantToInstallPWA')
  if (res) {
    const { value, time } = JSON.parse(res)
    if (Date.now() - time > 1000 * 60 * 60 * 24) {
      localStorage.setItem('isWantToInstallPWA', JSON.stringify({ value: true, time: Date.now() }))
      return true
    }
    return value
  } else {
    localStorage.setItem('isWantToInstallPWA', JSON.stringify({ value: true, time: Date.now() }))
    return true
  }
}

export function setIsWantToInstallPWA(isWantToInstallPWA: boolean) {
  if (isWantToInstallPWA) {
    localStorage.setItem('isWantToInstallPWA', JSON.stringify({ value: true, time: Date.now() }))
  } else {
    localStorage.setItem('isWantToInstallPWA', JSON.stringify({ value: false, time: Date.now() }))
  }
}

export function getChromeVersion() {
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)

  return raw ? parseInt(raw[2], 10) : false
}

export function getIsNavigateBack() {
  let res = localStorage.getItem('isNavigateBack')
    ? localStorage.getItem('isNavigateBack') === 'true'
      ? true
      : false
    : undefined
  if (res == undefined) {
    localStorage.setItem('isNavigateBack', 'false')
    res = false
  }
  return res
}
export function setIsNavigateBack(isNavigateBack: boolean) {
  localStorage.setItem('isNavigateBack', isNavigateBack ? 'true' : 'false')
}

export function getDataAddMoney() {
  const res = localStorage.getItem('dataAddMoney')
  return res ? (JSON.parse(res) as TAddMoney[]) : undefined
}

export function setDataAddMoney(data: TAddMoney[]) {
  localStorage.setItem('dataAddMoney', JSON.stringify(data))
}

export function clearDataAddMoney() {
  localStorage.removeItem('dataAddMoney')
}
