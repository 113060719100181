import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { LayoutHeader } from '@src/components/common/layout-header'
import TransactionDetailBody from '@src/components/transaction/transaction-detail-body'
import TransactionDetailFooter from '@src/components/transaction/transaction-detail-footer'
import { ROUTES } from '@src/constants/navbar/routes.constant'
import { TTransaction } from '@src/types/transaction'

const DATA: TTransaction = {
  bill_id: '000085752257',
  amount: 10000,
  created_at: '12:23 - 02/10/2024',
  payment_method: 'card',
  status: 'success',
  from: {
    id: '97419219821982198',
    name: 'Nguyen Van A',
  },
  is_received: true,
  is_read: true,
}

const PaymentSuccess = () => {
  const navigate = useNavigate()
  const ref = useRef<HTMLDivElement | null>(null)

  return (
    <div className='flex size-full flex-col'>
      <LayoutHeader className='!border-none !bg-transparent' title='Kết quả giao dịch' textColor='!text-base' />
      <div className='w-full flex-1 overflow-auto'>
        <div ref={ref} className='w-full p-4'>
          <TransactionDetailBody transaction={DATA} />
        </div>
      </div>

      <div className='w-full px-4 pt-2'>
        <TransactionDetailFooter
          onClose={() => {
            navigate(ROUTES.home)
          }}
          transactionRef={ref}
        />
      </div>
    </div>
  )
}

export default PaymentSuccess
