import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { INavigator } from '@src/constants/icons/navbar.icon'
import { getActivePageColor, navbar } from '@src/constants/navbar/navbar.constant'
import { setThemeColor } from '@src/utils/theme-color'
import { motion } from 'framer-motion'

const Navbar = () => {
  const navigate = useNavigate()
  const pathname = window.location.pathname
  const left = useMemo(() => {
    setThemeColor(getActivePageColor().themeColor)
    return (100 / navbar.length) * navbar.findIndex((item) => item.path === pathname)
  }, [pathname, navbar])

  return (
    <div
      id='app-navbar'
      className='fixed bottom-0 left-0 right-0 z-navbar mx-auto w-full translate-y-0 border-t border-soft-200 bg-white pb-ios-pb transition-all sm:!w-limitWidth'
    >
      <div className='relative grid w-full grid-cols-5 overflow-hidden'>
        <motion.div
          layoutId='shared-element'
          animate={{ left: `${left}%` }}
          initial={{ left: `${left}%` }}
          transition={{ duration: 0.1 }}
          className='pointer-events-none absolute top-0 flex w-[calc(100%/5)] justify-center text-center text-primary'
        >
          <INavigator />
        </motion.div>
        {navbar.map((item, index) => {
          const isActive = pathname === item.path
          const color = isActive
            ? '[--fill-color:theme(colors.primary)] [--stroke-color:theme(colors.white)] [--decor-color:theme(colors.primary)]'
            : '[--fill-color:theme(colors.transparent)] [--stroke-color:theme(colors.soft.400)] [--decor-color:theme(colors.soft.400)]'
          return (
            <button
              key={index}
              className={`flex w-full flex-col items-center gap-1 pb-2 pt-4 text-xs font-medium transition-all active:opacity-active ${isActive ? 'pointer-events-none text-strong-950' : 'text-text'} ${color}`}
              onClick={() => navigate(`${item.path}`)}
            >
              {item.icon()}
              <p>{item.title}</p>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default Navbar
