import { openToast } from '../../toaster/custom-toast'

import { SupportedBarcodeFormats } from './scan-qr.constants'

export async function connectCamera() {
  return await navigator.mediaDevices.getUserMedia({
    audio: false,
    video: { facingMode: 'environment' },
  })
}

type TEventCamera = {
  video: HTMLVideoElement | null
  stream?: MediaStream
}

export async function openCamera({ video, stream }: TEventCamera) {
  if (!video || !stream) return
  video.srcObject = stream
}

export async function closeCamera({ video, stream }: TEventCamera) {
  if (!video || !stream) return
  stream.getTracks().forEach((track) => track.stop())
  video.srcObject = null
}

export async function getBarcodeDetector({ source }: { source: ImageBitmapSource | null }) {
  // Early return if no source is provided
  if (!source) return

  // Check for BarcodeDetector support
  if ('BarcodeDetector' in window) {
    try {
      const supportedFormats = await BarcodeDetector.getSupportedFormats()
      const barcodeDetector = new BarcodeDetector({ formats: supportedFormats })
      const barcodes = await barcodeDetector.detect(source)
      barcodes.forEach((barcode) => {
        openToast({
          message: (SupportedBarcodeFormats[barcode.format] ?? barcode.format) + ' detected: ' + barcode.rawValue,
          type: 'success',
        })
      })
      return barcodes
    } catch (error) {
      console.error('Error initializing BarcodeDetector:', error)
    }
  } else {
    // Notify if BarcodeDetector is unsupported
    openToast({
      message: 'BarcodeDetector is not supported in this browser.',
      type: 'error',
      duration: 5000,
    })
    console.warn('BarcodeDetector is not supported in this browser.')
    throw new Error('BarcodeDetector is not supported in this browser.')
  }
}
