import { useEffect, useState } from 'react'
import { ITrashBin } from '@src/constants/icons/home.icon'
import { getDataAddMoney, setDataAddMoney } from '@src/utils/local-storage'
import { formatCurrency } from '@src/utils/transaction'

import { TAddMoney } from './add-money'

const SaveDataAddMoney = ({
  data,
  saveDataAddMoney,
  onChange,
}: {
  data: TAddMoney
  saveDataAddMoney?: TAddMoney[]
  onChange: (data: TAddMoney) => void
}) => {
  const [selected, setSelected] = useState(-1)
  const [isShow, setIsShow] = useState(true)

  useEffect(() => {
    if (selected === -1) {
      return
    }

    if (
      !saveDataAddMoney ||
      saveDataAddMoney.length === 0 ||
      data.amount != saveDataAddMoney[selected].amount ||
      data.content != saveDataAddMoney[selected].content
    ) {
      setSelected(-1)
    }
  }, [data, saveDataAddMoney])

  if (!saveDataAddMoney || saveDataAddMoney.length === 0) {
    return <></>
  }
  if (!isShow) {
    return <></>
  }
  return (
    <div className='flex w-full flex-col'>
      <p className='text-sm font-semibold'>Số tiền đã lưu</p>
      <div className='w-full pt-1.5'>
        <div className='flex flex-nowrap items-center gap-3 overflow-auto'>
          {saveDataAddMoney.map((item, index) => (
            <DataAddMoneyItem
              key={index}
              item={item}
              index={index}
              selected={selected}
              setSelected={setSelected}
              onChange={onChange}
              setIsShow={setIsShow}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SaveDataAddMoney

function DataAddMoneyItem({
  item,
  index,
  selected,
  setSelected,
  onChange,
  setIsShow,
}: {
  item: TAddMoney
  index: number
  selected: number
  setSelected: (index: number) => void
  onChange: (data: TAddMoney) => void
  setIsShow: (value: boolean) => void
}) {
  return (
    <div
      data-active={selected === index}
      className='relative flex min-w-fit cursor-pointer flex-col gap-1 rounded-lg border border-gray px-3 py-1.5 data-[active=true]:border-primary/30 data-[active=true]:bg-secondary/10 data-[active=true]:text-primary'
      onClick={() => {
        onChange(item)
        setSelected(index)
      }}
    >
      <span className='text-sm font-semibold'>{formatCurrency(item.amount)}đ</span>
      <span className='text-xs'>{item.content}</span>

      {selected === index && (
        <button
          className='absolute right-1 top-1 -translate-y-1/2 translate-x-1/2 cursor-pointer'
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setSelected(-1)
            const arr = getDataAddMoney()
            if (arr) {
              const newArr = arr.filter((value) => {
                return !(item.amount == value.amount && item.content == value.content)
              })
              setDataAddMoney(newArr)
              if (newArr.length === 0) {
                setIsShow(false)
              }
            }
            onChange({ amount: 0, content: '' })
          }}
        >
          <span className='block origin-center scale-50 rounded-full border border-sub-300 bg-white p-2 text-sub-600 shadow-2xl'>
            <ITrashBin />
          </span>
        </button>
      )}
    </div>
  )
}
