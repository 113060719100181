import { InputHTMLAttributes } from 'react'
import { IClose } from '@src/constants/icons/home.icon'

const CustomInput = ({
  label,
  rightContent,
  hintOptions,
  hintOnchange,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  rightContent?: JSX.Element
  hintOptions?: string[]
  hintOnchange?: (value: string) => void
}) => {
  return (
    <label className='group flex w-full flex-col gap-2 transition-all duration-500'>
      <p className='text-sm font-semibold'>{label}</p>
      <div className='flex w-full items-center rounded-md border border-sub-300 px-4 py-3 group-focus-within:border-primary'>
        <input {...props} className={`w-full bg-transparent outline-none ${props.className}`} />
        {rightContent}
        <button
          data-hidden={!props.value}
          className='block translate-x-1/2 cursor-pointer text-sub-600 data-[hidden=true]:hidden sm:hidden'
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            props.onChange?.({
              target: {
                value: '',
              },
            } as any)
          }}
        >
          <IClose />
        </button>
      </div>

      {hintOptions && hintOptions.length > 0 && (
        <div className='pointer-events-none max-h-0 origin-top scale-y-0 transition-all duration-300 hover:pointer-events-auto hover:max-h-[200px] hover:scale-y-100 group-focus-within:pointer-events-auto group-focus-within:max-h-[200px] group-focus-within:scale-y-100'>
          <div className='flex w-full flex-nowrap items-center gap-2 overflow-auto pt-1'>
            {hintOptions.map((item, index) => {
              return (
                <button
                  className='min-w-fit rounded-full border border-primary/30 bg-secondary/10 px-2.5 py-1 text-xs font-bold text-primary'
                  key={index}
                  onClick={() => {
                    hintOnchange?.(item)
                  }}
                >
                  {item}
                </button>
              )
            })}
          </div>
        </div>
      )}
    </label>
  )
}

export default CustomInput
