import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LayoutHeader } from '@src/components/common/layout-header'
import { HomeBackground } from '@src/components/home'
import BlueNoteCard from '@src/components/reward/blue-note-card'
import { openToast } from '@src/components/toaster/custom-toast'
import { theme_bank } from '@src/constants/bank.constants'
import { IArrowUp, IRefreshArrow, IVoucherPlus } from '@src/constants/icons/home.icon'
import IconWrapper from '@src/constants/icons/icon-wrapper'
import usePaymentStore from '@src/store/states/payment.state'
import { getThemeBank } from '@src/utils/theme-color'
import { formatCurrency } from '@src/utils/transaction'

import PaymentConfirm from '../confirm'

import BottomSection from './bottom-section'

function Card() {
  return (
    <div className='relative'>
      <BlueNoteCard />

      <div className='absolute inset-0 flex flex-col justify-between p-4'>
        <div className='flex items-center justify-between'>
          <img src='/bank-theme/BVB_Logo.png' alt='logo bvbank' className='h-8' />
          <img src='/common/icon-visa.svg' alt='icon visa' className='h-4' />
        </div>

        <div className='flex flex-col text-base font-semibold text-[#1f2b7b]'>
          <p>NGUYỄN THỊ MỘNG TRINH</p>
          <p>**** **** **** 1234</p>
        </div>
      </div>
    </div>
  )
}

const DATA = {
  amount: 10000,
  data: new Date(),
  valueQR: '',
}

const PaymentSummary = () => {
  const navigate = useNavigate()
  const { valueQR } = usePaymentStore()
  DATA['valueQR'] = valueQR ?? ''

  const bank = theme_bank[getThemeBank()]

  const [openConfirm, setOpenConfirm] = useState(false)

  return (
    <div className='flex size-full flex-col'>
      <HomeBackground />
      <LayoutHeader
        className='!border-none !bg-transparent'
        onBack={() => navigate('/scan-qr')}
        title={'Tóm tắt giao dịch'}
        textColor='!text-white'
      />

      <main className='relative flex flex-1 flex-col gap-2 overflow-auto p-6 pt-2'>
        <div className='flex h-fit w-full flex-col gap-4 rounded-2xl bg-white p-6'>
          <div className='flex w-full flex-col items-center gap-1 border-b border-dashed border-gray pb-4'>
            <IconWrapper size={32} rounded='12px'>
              <IRefreshArrow />
            </IconWrapper>
            <p className='w-full scale-y-95 text-center text-2xl font-bold text-black'>
              {formatCurrency(DATA.amount)} đ
            </p>
            <p className='text-xs font-normal opacity-70'>Bạn dùng {bank.htmlTitle} chuyển tiền</p>
          </div>

          <div className='flex flex-col gap-2'>
            {[
              { title: 'Người nhận', value: 'STARBUCKS VIET NAM' },
              { title: 'Ngân hàng', value: bank.title },
              { title: 'Số tài khoản', value: '**** 2654' },
              { title: 'Phí dịch vụ', value: 'Miễn phí' },
            ].map((item, index) => (
              <InforItem key={index} {...item} />
            ))}
          </div>
        </div>
        <div
          className='flex w-full cursor-pointer items-center gap-2 rounded-2xl bg-white px-6 py-4'
          onClick={() => {
            openToast({
              message: 'Coming soon',
            })
          }}
        >
          <IconWrapper size={32} rounded='12px'>
            <IVoucherPlus />
          </IconWrapper>
          <div className='flex-1 overflow-auto text-sm text-strong-950'>
            <p className='w-full truncate'>Thêm ưu đãi</p>
            <p className='w-full truncate text-xs opacity-80'>Chọn để khám phá nhiều ưu đãi</p>
          </div>
          <span className='rotate-90 scale-x-90 text-strong-950'>
            <IArrowUp />
          </span>
        </div>
      </main>

      <BottomSection data={DATA} handleConfirm={() => setOpenConfirm(true)} />
      <PaymentConfirm isOpen={openConfirm} onClose={() => setOpenConfirm(false)} />
    </div>
  )
}

export default PaymentSummary

export function InforItem(item: { title: string; value: string }) {
  return (
    <div className='flex w-full items-center gap-2'>
      <p className='text-sm font-light opacity-90'>{item.title}</p>
      <p className='flex-1 text-end text-sm font-medium'>{item.value}</p>
    </div>
  )
}
